/*---------------------------------------------------------------------------*\
  Footer
\*---------------------------------------------------------------------------*/

.content-info {
  padding: { top: $footer-padding; bottom: $footer-padding; }
  color: $green-light;
  background-color: $green-darkest;

  p {
    margin-bottom: 0;
    font-size: (11/14)+em;
    text-transform: uppercase;
  }

  a { color: $green-light; }
}
