/*---------------------------------------------------------------------------*\
  Base
\*---------------------------------------------------------------------------*/

body {
  font-family: $font-raleway-regular;
}

p {
  margin-bottom: (30/16)+em;
  font-size: (16/14)+em;
  line-height: (26/16)+em;
}

img {
  max-width: 100%;
  height: auto;
}
