/*---------------------------------------------------------------------------*\
  Blog Posts
\*---------------------------------------------------------------------------*/

.blog {
  article.post {
    margin-bottom: (30/14)+em;
  }

  .entry-title {
    a {
      color: $green-dark;

      &:hover {
        color: $brown-medium;
        text-decoration: none;
      }
    }
  }
}

.single {
  .post {
    header {
      margin: { top: 0; bottom: (30/14)+em; }
      padding-bottom: (9/14)+em;
      border-bottom: 1px solid $brown-medium;

      h1 {
        display: inline-block;
        padding-right: (60/48)+em;
        font-size: (48/14)+em;
        font-family: $font-satisfy;
        color: $green-dark;
        background-image: url('../../dist/img/bg-page-title.png');
        background-position: right center;
        background-repeat: no-repeat;
      }
    }
  }
}
