/*---------------------------------------------------------------------------*\
  Sidebar
\*---------------------------------------------------------------------------*/

.sidebar {
  display: none;

  // Medium Desktops
  @media (min-width: $screen-md-min){
    display: block;
  }

  .widget {
    // Medium Desktops
    @media (min-width: $screen-md-min){
      margin-bottom: (30/14)+em;
    }

    // Large Desktops
    @media (min-width: $screen-lg-min){
      padding: { left: (10/14)+em; right: (10/14)+em; }
    }

    &.text-4 {
      padding-top: (147/14)+em; // Height of background image
      background-image: url('../../dist/img/bg-vip-list.jpg');
      background-position: top center;
      background-repeat: no-repeat;
    }

    &__title { display: none; }

    p {
      font-size: (14/14)+em;
    }
  }

  .widget.text-4 {
    .textwidget {
      padding: (12/14)+em;
      background-color: $beige-light;
    }
  }

  .btn {
    width: 100%;
    padding: { top: (20/26)+em; bottom: (20/26)+em; }
    font-size: (26/14)+em;
  }
}
