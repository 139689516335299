/*----------------------------------------------------------------------------*\
  Gravity Forms
\*----------------------------------------------------------------------------*/

.gform_wrapper {
  ul {
    @extend .list-unstyled;
  }

  li {
    @extend .form-group;
  }

  form {
    margin-bottom: 0;
  }

  .gfield_required {
    padding-left: 1px;
    color: $state-danger-text;
  }
}

.ginput_container {
  input,
  select,
  textarea {
    @extend .form-control;
    border-radius: 0px;
    box-shadow: none;
  }

  textarea {
    height: auto;
  }
}

.gform_button {
  @extend .btn;
  @extend .btn-warning;
}

.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }

  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    @include form-control-focus( $alert-danger-text );
  }
}

.gform_validation_container {
  display: none;
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.gform_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_footer {
  clear: both;
}
