/*---------------------------------------------------------------------------*\
  WordPress Classes
\*---------------------------------------------------------------------------*/

.wp-caption {
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.wp-caption-text {
  padding: 10px;
  font-size: 12px;
}

.alignleft,
.alignright {
  // Landscape {
  @media (orientation: landscape){
    display: block;
    margin: { left: auto; right: auto; }
  }
}

.alignnone {
  margin-bottom: 20px;

  // Small Tablets
  @media (min-width: $screen-sm-min){
    margin: { right: 20px; bottom: 20px; }
  }

  // Medium Desktops
  @media (min-width: $screen-md-min){
    margin-bottom: 0;
  }
}

.alignleft {
  // Small Tablets
  @media (min-width: $screen-sm-min){
    float: left;
    margin: { right: 20px; bottom: 20px; }
  }
}

.alignright {
  // Small Tablets
  @media (min-width: $screen-sm-min){
    float: right;
    margin: { left: 20px; bottom: 20px; }
  }
}
