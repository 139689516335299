/*---------------------------------------------------------------------------*\
  Offcanvas

  1. Globals
  2. Offcanvas Navigation
\*---------------------------------------------------------------------------*/

.navbar-offcanvas {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 60%;
  height: 100%;
  background-color: $brown-medium;
  transition: all .25s ease;
  transform: translateZ(0);

  // Small Tablets
  @media (min-width: $screen-sm-min){
    width: 40%;
  }

  // Medium Desktops
  @media (min-width: $screen-md-min){
    display: none;
  }

  &.active {
    box-shadow: 0 10px 10px rgba(black, 0.55);

    span {
      background-color: transparent;
    }

    span:after,
    span:before {
      top: 0;
    }

    span:after {
      transform: rotate(-45deg);
    }

    span:before {
      transform: rotate(45deg);
    }
  }

  &--right {
    right: -60%;

    // Small Tablets
    @media (min-width: $screen-sm-min){
      right: -40%;
    }

    &.active {
      transform: translateX(-100%);
    }
  }

  &__toggle {
    position: absolute;
    top: 20px;
    left: -60px;
    z-index: 99999;
    padding: 30px;
    background-color: $brown-medium;
    border: none;
    -webkit-tap-highlight-color: transparent;

    span,
    span:after,
    span:before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      width: 25px;
      height: 2px;
      margin: { left: auto; right: auto; }
      background-color: white;
      cursor: pointer;
      content: "";
      transition: all 0.1s ease-in-out;
    }

    span:after,
    span:before {
      &:hover {
        top: 0px;
      }
    }

    span:after {
      bottom: 7px;
    }

    span:before {
      top: 7px;
    }
  }
}

/*---------------------------------------------------------------------------*\
  2. Offcanvas Navigation
\*---------------------------------------------------------------------------*/

.nav-offcanvas {
  margin-bottom: 0;
  padding: 20px;
  list-style: none;

  li {
    margin-bottom: 10px;
  }

  a {
    display: block;
    padding: { top: 5px; bottom: 5px; }
    font-size: 21px;
    color: white;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }
  }
}
