/*---------------------------------------------------------------------------*\
  Footer Blocks
\*---------------------------------------------------------------------------*/

.footer-blocks {
  padding: { top: $footer-blocks-padding; bottom: $footer-blocks-padding; }
  color: $beige-light;
  text-align: center;
  background-color: $green-dark;

  // Small Tablets
  @media (min-width: $screen-sm-min){
    text-align: left;
    @include clearfix;
  }
}

.footer-block {
  margin-bottom: (30/14)+em;
  color: $beige-light;

  // Small Tablets
  @media (min-width: $screen-sm-min){
    display: inline-block;
    float: left;
    margin: { bottom: 0; left: (80/14)+em; }
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    // Small Tablets
    @media (min-width: $screen-sm-min){
      padding-top: (45/14)+em;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    .footer-block__title { display: none; }
  }

  &:nth-child(1) {
    // Small Tablets
    @media (min-width: $screen-sm-min){
      margin-left: 0;
    }
  }

  &:nth-child(2) {
    display: none;

    // Medium Desktops
    @media (min-width: $screen-md-min){
      display: inline-block;
      width: 25%;
    }
  }

  &:nth-child(3) {
    // Small Tablets
    @media (min-width: $screen-sm-min){
      max-width: 20%;
    }

    // Medium Desktops
    @media (min-width: $screen-md-min){
      max-width: 15%;
    }

    // Large Desktops
    @media (min-width: $screen-lg-min){
      max-width: 25%;
    }
  }

  &:nth-child(4) {
    a {
      // Small Tablets
      @media (min-width: $screen-sm-min){
        display: block;
        margin-bottom: (15/14)+em;
      }

      &:hover {
        color: $brown-medium;
        text-decoration: none;
      }
    }

    .fa {
      margin-right: (10/21)+em;
      font-size: (21/14)+em;
      color: $brown-medium;
    }

    .text {
      display: none;

      // Small Tablets
      @media (min-width: $screen-sm-min){
        display: inline-block;
      }
    }

    .icn {
      &-tripadvisor {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: (10/14)+em;
        text-align: top;
        background-image: url('../../dist/img/icn-tripadvisor.png');
        background-position: center;
        background-repeat: no-repeat;

        // Small Tablets
        @media (min-width: $screen-sm-min){
          float: left;
        }
      }
    }
  }

  &__title {
    margin-bottom: (20/18)+em;
    text-transform: uppercase;
  }

  img {
    margin-bottom: (20/14)+em;
  }

  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  li {
    margin-bottom: (10/14)+em;
    text-transform: uppercase;

    &:nth-child(odd),
    &:nth-child(even) {
      // Small Tablets
      @media (min-width: $screen-sm-min){
        width: 30%;
      }
    }

    &:nth-child(odd) {
      // Small Tablets
      @media (min-width: $screen-sm-min){
        clear: left;
        float: left;
      }
    }

    &:nth-child(even) {
      // Small Tablets
      @media (min-width: $screen-sm-min){
        clear: right;
        float: right;
      }
    }
  }

  a {
    color: $beige-light;
  }
}

.recent-post {
  margin-bottom: (20/14)+em;

  &__published {
    display: inline-block;
    margin-bottom: (5/14)+em;
    font-family: $font-raleway-bold;
  }
}
