/*---------------------------------------------------------------------------*\
  Map
\*---------------------------------------------------------------------------*/

#map-canvas {
  height: 300px;

  img { max-width: none; }

  h4 {
    font-size: (21/14)+em;
  }

  a {
    font-size: (18/14)+em;

    &:hover {
      text-decoration: none;
    }
  }

  .gm-style-iw {
    padding: (10/14)+em;

    h4 {
      color: $gold-medium;
    }

    a {
      color: $green-dark;

      &:hover {
        color: $green-light;
      }
    }
  }
}
