/*---------------------------------------------------------------------------*\
  Hero
\*---------------------------------------------------------------------------*/

.home.page {
  .hero__branding {
    max-width: 467px;
  }
}

.blog,
.page,
.single {
  .hero__branding {
    max-width: 267px;
  }
}

.hero {
  position: relative;

  &__branding {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    margin: { left: auto; right: auto; }
  }

  .soliloquy-container {
    .soliloquy-image {
      width: 100%;
    }
  }
}
