/*---------------------------------------------------------------------------*\
  Variables
\*---------------------------------------------------------------------------*/

$facebook: #3b5998;
$twitter: #00aced;
$googleplus: #dd4b39;
$linkedin: #007bb6;
$youtube: #bb0000;
$instagram: #517fa4;
$pinterest: #cb2027;
$flickr: #ff0084;
$tumblr: #32506d;
$foursquare: #0072b1;
$dribble: #ea4c89;
$vine: #00bf8f;

$beige-light: #f3e6c2;
$brown-medium: #ba6314;
$brown-dark: #804209;
$gold-medium: #d3a331;
$green-light: #586656;
$green-dark: #333c32;
$green-darkest: #2c342b;

$intro-padding: (40/14)+em;
$content-padding: (40/14)+em;
$footer-blocks-padding: (30/14)+em;
$footer-padding: (20/14)+em;

$font-raleway-regular: 'ralewayregular';
$font-raleway-bold: 'ralewaybold';
$font-satisfy: 'Satisfy', cursive;
