/*---------------------------------------------------------------------------*\
  Typography
\*---------------------------------------------------------------------------*/

@font-face {
  font-family: 'ralewayregular';
  src: url('../../dist/fonts/raleway/raleway-regular-webfont.eot');
  src: url('../../dist/fonts/raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../dist/fonts/raleway/raleway-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/raleway/raleway-regular-webfont.woff') format('woff'),
       url('../../dist/fonts/raleway/raleway-regular-webfont.ttf') format('truetype'),
       url('../../dist/fonts/raleway/raleway-regular-webfont.svg#ralewayregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ralewaybold';
  src: url('../../dist/fonts/raleway/raleway-bold-webfont.eot');
  src: url('../../dist/fonts/raleway/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../dist/fonts/raleway/raleway-bold-webfont.woff2') format('woff2'),
       url('../../dist/fonts/raleway/raleway-bold-webfont.woff') format('woff'),
       url('../../dist/fonts/raleway/raleway-bold-webfont.ttf') format('truetype'),
       url('../../dist/fonts/raleway/raleway-bold-webfont.svg#ralewaybold') format('svg');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h2 {
  margin-bottom: (20/40)+em;
  font-size: (40/14)+em;
  font-family: $font-satisfy;
  color: $gold-medium;
}

h4 {
  font-size: (18/14)+em;
  font-family: $font-raleway-bold;
}

h6 {
  font-size: (14/14)+em;
}
