/*---------------------------------------------------------------------------*\
  Home Page
\*---------------------------------------------------------------------------*/

.intro {
  padding: { top: $intro-padding; bottom: $intro-padding; }
  text-align: center;

  // Medium Desktops
  @media (min-width: $screen-md-min){
    text-align: left;
  }

  &--about {
    color: $beige-light;
    background-color: $green-dark;
  }

  &--amenities {
    padding: { top: 0; bottom: 0; }
    color: $beige-light;
    background-color: $brown-dark;
  }
}

/*---------------------------------------------------------------------------*\
  About
\*---------------------------------------------------------------------------*/

.intro--about {
  text-align: left;

  .col-md-9 {
    a {
      color: $beige-light;
      text-decoration: underline;

      &:hover {
        color: $gold-medium;
      }
    }
  }

  .btn {
    width: 100%;
    max-width: 243px;
    padding: { top: (20/26)+em; bottom: (20/26)+em; }
    font-size: (26/14)+em;

    // Small Tablets
    @media (min-width: $screen-sm-min){
      //margin-top: (45/14)+em;
      //padding: { top: (20/21)+em; bottom: (20/21)+em; }
      //font-size: (21/14)+em;
    }

    // Medium Desktops
    @media (min-width: $screen-md-min){
      margin-top: (40/14)+em;
      padding: { top: (20/26)+em; bottom: (20/26)+em; }
      font-size: (26/14)+em;
    }
  }
}

/*---------------------------------------------------------------------------*\
  Amenities
\*---------------------------------------------------------------------------*/

.intro--amenities {
  text-align: left;

  // Medium Desktops
  @media (min-width: $screen-md-min){
    @include clearfix;
  }

  .col {
    // Medium Desktops
    @media (min-width: $screen-md-min){
      float: left;
      width: 50%;
    }

    &--left {
      height: 300px;
      background-image: url('../../dist/img/bg-amenities.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      // Medium Desktops
      @media (min-width: $screen-md-min){
        height: initial;
      }
    }

    &--right {
      padding: (30/14)+em (15/14)+em;
      background-image: url('../../dist/img/bg-tree.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;

      // Medium Desktops
      @media (min-width: $screen-md-min){
        max-width: 585px;
        padding: (40/14)+em;
        background-position: 30px center;
      }
    }
  }
}
