/*---------------------------------------------------------------------------*\
  Buttons
\*---------------------------------------------------------------------------*/

.btn {
  font-size: (16/14)+em;
  font-family: $font-raleway-regular;
  color: $beige-light;
  text-transform: uppercase;
  border: 0;
  border-radius: 0;
  box-shadow: none;

  &--brown {
    background-color: $brown-medium;

    &:hover {
      color: $green-dark;
      background-color: $gold-medium;
    }
  }
}
