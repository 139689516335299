/*---------------------------------------------------------------------------*\
  Content
\*---------------------------------------------------------------------------*/

.page-header {
  margin: { top: 0; bottom: (30/14)+em; }
  border-color: $brown-medium;

  h1 {
    display: inline-block;
    padding-right: (60/48)+em;
    font-size: (48/14)+em;
    font-family: $font-satisfy;
    color: $green-dark;
    background-image: url('../../dist/img/bg-page-title.png');
    background-position: right center;
    background-repeat: no-repeat;
  }
}

.content {
  padding: { top: $content-padding; bottom: $content-padding; }
}

.main {
  color: $brown-dark;

  a {
    color: $gold-medium;
  }

  li {
    font-size: (16/14)+em;
    line-height: (30/16)+em;

    li { font-size: (16/16)+em; }
  }
}
