/*---------------------------------------------------------------------------*\
  Header
\*---------------------------------------------------------------------------*/

.navbar {
  display: none;

  // Medium Desktops
  @media (min-width: $screen-md-min){
    display: block;
    min-height: initial;
    margin-bottom: 0;
    border: 0;
  }
}

.navbar-default {
  // Medium Desktops
  @media (min-width: $screen-md-min){
    background-color: $brown-dark;
  }

  .navbar-nav {
    > li {
      a {
        font-size: (18/14)+em;
        font-family: $font-raleway-regular;
        color: $beige-light;
        text-transform: uppercase;

        // Medium Desktops
        @media (min-width: $screen-md-min){
          padding: (11/14)+em;
          font-size: (14/14)+em;
        }

        // Large Desktops
        @media (min-width: $screen-lg-min){
          padding: (11/18)+em;
          font-size: (18/14)+em;
        }

        &:hover {
          color: $gold-medium;
        }
      }

      > .mobile-touch {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        display: block;
        width: 40px;
        height: 40px;

        // Small Tablets
        @media (min-width: $screen-sm-min){
          display: none;
        }

        .caret {
          display: block;
          margin: 18px auto;
          color: black;
          border-width: 5px;
        }
      }
    }

    .active {
      a,
      a:hover {
        color: $gold-medium;
        background-color: transparent;
      }
    }

    .menu-home {
      a {
        // Medium Desktops
        @media (min-width: $screen-md-min){
          padding-left: 0;
        }
      }
    }

    .menu-book-now {
      a {
        color: $brown-dark;
        background-color: $gold-medium;

        &:hover {
          color: $brown-dark;
          background-color: darken($gold-medium, 5%);
        }
      }
    }
  }
}

/*---------------------------------------------------------------------------*\
  Dropdown
\*---------------------------------------------------------------------------*/

.dropdown:hover {
  .dropdown-menu {
    // Medium Desktops
    @media (min-width: $screen-md-min){
      display: block;
      box-shadow: 0 5px 10px -1px rgba(black, 0.10);
    }
  }
}
